
.loaderSpinner {
    border: 10px solid #eaf0f6;
    border-radius: 50%;
    border-top: 10px solid lightgreen;
    width: 100px;
    height: 100px;
    animation: spinner 1.5s linear infinite;
  }
  
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }