body{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
}

.iframe {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0;
  display: block;
  margin: 0;
  top: 0;
  left: 0;
  position: fixed;
}